enum StepCardVariant {
  FOCUSED_COMPLETE = "FOCUSED_COMPLETE",
  FOCUSED_INCOMPLETE = "FOCUSED_INCOMPLETE",
  FOCUSED_IN_REVIEW = "FOCUSED_IN_REVIEW",
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  IN_REVIEW = "IN_REVIEW",
  // Variants with revamped styling - used in LRO and Reporting for Preactive merchants
  FOCUSED_COMPLETE_REVAMPED = "FOCUSED_COMPLETE_REVAMPED",
  FOCUSED_INCOMPLETE_REVAMPED = "FOCUSED_INCOMPLETE_REVAMPED",
  COMPLETE_REVAMPED = "COMPLETE_REVAMPED",
  INCOMPLETE_REVAMPED = "INCOMPLETE_REVAMPED",
  FOCUSED_IN_REVIEW_REVAMPED = "FOCUSED_IN_REVIEW_REVAMPED",
  IN_REVIEW_REVAMPED = "IN_REVIEW_REVAMPED",
}

export default StepCardVariant;
