import {
  AlignItems,
  Box,
  ColorPreset,
  FontWeight,
  JustifyContent,
  PlainButton,
  Space,
  Text,
  TextAlign,
  TypeScale,
} from "@gocardless/flux-react";

import { StepCardProps } from "./StepCard";

export const StepCardIncompleteRevamped: React.FC<StepCardProps> = ({
  index,
  header,
  tag,
  onBoxClick,
}) => (
  <PlainButton
    onClick={onBoxClick}
    css={{ width: "100%" }}
    data-testid="step-card-incomplete"
  >
    <Box
      layout="flex"
      flexDirection="row"
      alignItems={AlignItems.Center}
      gutterV={0.25}
      gutterH={[1, 2]}
    >
      <Box
        layout="flex"
        flexDirection="row"
        justifyContent={JustifyContent.Center}
        alignItems={AlignItems.Center}
        alignSelf={AlignItems.Start}
        flexShrink={0}
        width="32px"
        height="32px"
        spaceAfter={1}
        bg={ColorPreset.BackgroundLight_01}
        borderWidth={1}
        borderColor={ColorPreset.IconOnDark_03}
        borderRadius={2}
      >
        <Text
          color={ColorPreset.IconOnDark_04}
          weight={FontWeight.SemiBold}
          size={TypeScale.Size_02}
        >
          {index}
        </Text>
      </Box>

      <Box layout="flex" flexDirection={["column", null, "row"]}>
        <Text size={3} weight={FontWeight.SemiBold} textAlign={TextAlign.Left}>
          {header}
        </Text>

        {tag ? (
          <>
            <Space layout="inline" h={0.5} v={0.5} />
            {tag}
          </>
        ) : null}
      </Box>
    </Box>
  </PlainButton>
);
