import { ComponentType, FC, MouseEventHandler, ReactNode } from "react";

import { StepCardComplete } from "./StepCardComplete";
import { StepCardCompleteRevamped } from "./StepCardCompleteRevamped";
import { StepCardFocusedComplete } from "./StepCardFocusedComplete";
import { StepCardFocusedCompleteRevamped } from "./StepCardFocusedCompleteRevamped";
import { StepCardFocusedInReview } from "./StepCardFocusedInReview";
import { StepCardFocusedIncomplete } from "./StepCardFocusedIncomplete";
import { StepCardFocusedIncompleteRevamped } from "./StepCardFocusedIncompleteRevamped";
import { StepCardInReview } from "./StepCardInReview";
import { StepCardIncomplete } from "./StepCardIncomplete";
import { StepCardIncompleteRevamped } from "./StepCardIncompleteRevamped";
import { StepCardFocusedInReviewRevamped } from "./StepCardFocusedInReviewRevamped";
import { StepCardInReviewRevamped } from "./StepCardInReviewRevamped";
import StepCardVariant from "./StepCardVariant";

export interface StepCardProps {
  children: ReactNode;
  onBoxClick: MouseEventHandler<HTMLButtonElement>;
  variant: StepCardVariant;
  index?: number;
  imgSrc?: string;
  header: ReactNode;
  tag?: ReactNode;
}

const StepCard: FC<StepCardProps> = (props) => {
  const { variant } = props;

  const variantComponents: Record<
    StepCardVariant,
    ComponentType<StepCardProps>
  > = {
    [StepCardVariant.FOCUSED_COMPLETE]: StepCardFocusedComplete,
    [StepCardVariant.FOCUSED_INCOMPLETE]: StepCardFocusedIncomplete,
    [StepCardVariant.FOCUSED_IN_REVIEW]: StepCardFocusedInReview,
    [StepCardVariant.COMPLETE]: StepCardComplete,
    [StepCardVariant.INCOMPLETE]: StepCardIncomplete,
    [StepCardVariant.IN_REVIEW]: StepCardInReview,
    [StepCardVariant.FOCUSED_COMPLETE_REVAMPED]:
      StepCardFocusedCompleteRevamped,
    [StepCardVariant.FOCUSED_INCOMPLETE_REVAMPED]:
      StepCardFocusedIncompleteRevamped,
    [StepCardVariant.COMPLETE_REVAMPED]: StepCardCompleteRevamped,
    [StepCardVariant.INCOMPLETE_REVAMPED]: StepCardIncompleteRevamped,
    [StepCardVariant.FOCUSED_IN_REVIEW_REVAMPED]:
      StepCardFocusedInReviewRevamped,
    [StepCardVariant.IN_REVIEW_REVAMPED]: StepCardInReviewRevamped,
  };

  const VariantComponent = variantComponents[variant];

  return <VariantComponent {...props} />;
};

export default StepCard;
