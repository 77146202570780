import {
  AlignItems,
  Box,
  BoxProps,
  Color,
  ColorPreset,
  FontWeight,
  JustifyContent,
  ResponsiveValue,
  Space,
  SpaceScale,
  Text,
  TypeScale,
  Visibility,
} from "@gocardless/flux-react";

import { StepCardProps } from "./StepCard";

const StyledIndex = ({
  index,
  spaceAfter = 0,
}: {
  index: StepCardProps["index"];
  spaceAfter?: ResponsiveValue<SpaceScale>;
}) => {
  const containerStyle: BoxProps = {
    layout: "flex",
    flexDirection: "row",
    justifyContent: JustifyContent.Center,
    alignItems: AlignItems.Center,
    alignSelf: AlignItems.Start,
    flexShrink: 0,
    width: "32px",
    height: "32px",
    borderWidth: 1,
    borderColor: Color.Greystone_700,
    borderRadius: 2,
    bg: Color.White,
  };

  return (
    <Box {...containerStyle} spaceAfter={spaceAfter}>
      <Text
        color={ColorPreset.BorderOnLight_01}
        weight={FontWeight.SemiBold}
        size={TypeScale.Size_02}
      >
        {index}
      </Text>
    </Box>
  );
};

export const StepCardFocusedIncompleteRevamped: React.FC<StepCardProps> = ({
  index,
  imgSrc,
  header,
  tag,
  children,
}) => (
  <Box
    layout="flex"
    flexDirection="row"
    bg={ColorPreset.BackgroundLight_01}
    borderWidth={1}
    borderColor={Color.Greystone_700}
    borderRadius={1}
    spaceAbove={0.25}
    spaceBelow={0.25}
    css={{ transform: "translateZ(0)" }} // fixes a drop-shadow filter rendering bug in Safari
  >
    <Visibility visible={["none", null, "block"]}>
      <Box layout="flex" flexDirection="column" spaceBefore={2} spaceAbove={2}>
        <StyledIndex index={index} />
      </Box>
    </Visibility>

    <Box
      layout="flex"
      flexDirection="column"
      alignItems={[AlignItems.Center, null, AlignItems.Start]}
      width="100%"
      spaceAbove={[1, null, 2]}
      spaceAfter={2}
      spaceBelow={2}
      spaceBefore={[2, null, 1]}
    >
      {imgSrc && (
        <Visibility visible={["block", null, "none"]}>
          <Box width="128px" height="128px">
            <img src={imgSrc} alt="" css={{ width: "100%", height: "100%" }} />
          </Box>
        </Visibility>
      )}

      <Box
        layout="flex"
        flexDirection={["column", null, null, null, "row"]}
        alignItems={[
          AlignItems.Center,
          null,
          AlignItems.FlexStart,
          null,
          AlignItems.Center,
        ]}
        spaceBelow={tag ? 1 : 0.25}
      >
        <Text
          layout={["block", null, null, null, "inline"]}
          size={3}
          weight={FontWeight.SemiBold}
          lineHeight={[null, null, 6]}
        >
          {header}
        </Text>

        {tag ? (
          <>
            <Space layout="inline" v={0.25} h={0.5} />
            {tag}
          </>
        ) : null}
      </Box>

      {children}
    </Box>

    {imgSrc && (
      <Visibility visible={["none", null, "block"]}>
        <Box
          layout="flex"
          flexDirection="column"
          justifyContent={JustifyContent.Center}
          alignItems={AlignItems.FlexEnd}
          height="100%"
          spaceAfter={2}
        >
          <Box width="128px" height="128px">
            <img src={imgSrc} alt="" css={{ width: "100%", height: "100%" }} />
          </Box>
        </Box>
      </Visibility>
    )}
  </Box>
);
