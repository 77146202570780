import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  FontWeight,
  JustifyContent,
  Space,
  Text,
  TextAlign,
  Visibility,
} from "@gocardless/flux-react";

import { StepCardProps } from "./StepCard";

export const StepCardFocusedIncomplete: React.FC<StepCardProps> = ({
  imgSrc,
  header,
  tag,
  children,
}) => (
  <Box
    layout="flex"
    flexDirection="row"
    bg={ColorPreset.BackgroundLight_01}
    borderWidth={1}
    borderColor={ColorPreset.BorderOnLight_02}
    borderRadius={1}
    css={{ transform: "translateZ(0)" }} // fixes a drop-shadow filter rendering bug in Safari
  >
    <Visibility visible={["none", null, "block"]}>
      <Box layout="flex" flexDirection="column" spaceBefore={2} spaceAbove={2}>
        <Box
          layout="flex"
          flexDirection="row"
          justifyContent={JustifyContent.Center}
          alignItems={AlignItems.Center}
          alignSelf={AlignItems.Start}
          flexShrink={0}
          width="32px"
          height="32px"
          borderWidth={1}
          borderColor={ColorPreset.BackgroundDark_04}
          borderRadius={2}
          bg={Color.White}
        />
      </Box>
    </Visibility>

    <Box
      layout="flex"
      flexDirection="column"
      alignItems={[AlignItems.Center, null, AlignItems.Start]}
      width="100%"
      spaceAbove={[1, null, 2]}
      spaceAfter={2}
      spaceBelow={2}
      spaceBefore={[2, null, 1]}
    >
      {imgSrc && (
        <Visibility visible={["block", null, "none"]}>
          <Box width="128px" height="128px">
            <img src={imgSrc} alt="" css={{ width: "100%", height: "100%" }} />
          </Box>
        </Visibility>
      )}

      <Box>
        <Text
          layout={["block", null, "inline"]}
          size={3}
          weight={FontWeight.SemiBold}
          textAlign={[TextAlign.Center, null, TextAlign.Left]}
          lineHeight={[null, null, 6]}
          spaceBelow={tag ? [0.5, null, 0] : undefined}
        >
          {header}
          {tag ? <Space layout="inline" h={[0, null, 0.5]} /> : null}
        </Text>

        {tag ? tag : null}
      </Box>

      <Space v={1} />

      {children}
    </Box>

    {imgSrc && (
      <Visibility visible={["none", null, "block"]}>
        <Box
          layout="flex"
          flexDirection="column"
          justifyContent={JustifyContent.Center}
          alignItems={AlignItems.FlexEnd}
          height="100%"
          spaceAfter={2}
        >
          <Box width="128px" height="128px">
            <img src={imgSrc} alt="" css={{ width: "100%", height: "100%" }} />
          </Box>
        </Box>
      </Visibility>
    )}
  </Box>
);
