import {
  AlignItems,
  Box,
  Color,
  FontWeight,
  Glyph,
  Icon,
  JustifyContent,
  PlainButton,
  Space,
  Text,
  TextAlign,
} from "@gocardless/flux-react";

import { StepCardProps } from "./StepCard";

export const StepCardCompleteRevamped: React.FC<StepCardProps> = ({
  header,
  tag,
  onBoxClick,
}) => (
  <PlainButton
    onClick={onBoxClick}
    css={{ width: "100%" }}
    data-testid="step-card-complete"
  >
    <Box
      layout="flex"
      flexDirection="row"
      alignItems={AlignItems.Center}
      gutterV={0.25}
      gutterH={[1, 2]}
    >
      <Box
        layout="flex"
        flexDirection="row"
        justifyContent={JustifyContent.Center}
        alignItems={AlignItems.Center}
        alignSelf={AlignItems.Start}
        flexShrink={0}
        width="32px"
        height="32px"
        spaceAfter={1}
        bg={Color.Greystone_700}
        borderWidth={1}
        borderColor={Color.Greystone_700}
        borderRadius={2}
      >
        <Icon name={Glyph.Tick} color={Color.White} size="12px" />
      </Box>

      <Box layout="flex" flexDirection={["column", null, "row"]}>
        <Text
          size={3}
          color={Color.Greystone_900}
          weight={FontWeight.SemiBold}
          textAlign={TextAlign.Left}
          decoration="line-through"
        >
          {header}
        </Text>

        {tag ? (
          <>
            <Space layout="inline" h={0.5} v={0.5} />
            {tag}
          </>
        ) : null}
      </Box>
    </Box>
  </PlainButton>
);
